<template>
    <div>
        <div v-if="preloader">
            <div class="default_box" itemscope itemtype="http://schema.org/Article" v-if="data">
                <div v-if="data.item && data.item.title" class="default_header">
                    <h1 v-html="data.item.title"></h1>
                    <meta itemprop="headline name" :content="data.item.title" />
                    <meta itemprop="description" :content="data.item.text ? $cutText(data.item.text, 100) : data.item.title" />
                </div>
                <div class="default_box_content bg_white p15">
                    <imageLoader v-if="data.item.images" :list="data.item.images"></imageLoader>
                    <p class="default_item_text" v-if="data.item.text" v-html="$htmContext(data.item.text)" itemprop="articleBody"></p>
                    <p class="default_item_source" v-if="data.item.source_url && (data.item.source_url.includes('http://') || (data.item.source_url && data.item.source_url.includes('https://')))" itemprop="author">
                        <b>Источник: </b> <a :href="data.item.source_url" target="_blank" rel="nofollow">{{ data.item.source_url }}</a>
                    </p>
                    <p v-else-if="data.item.source_url" class="default_item_source" itemprop="author"><b>Источник: </b> {{ data.item.source_url }}</p>
                    <time itemprop="datePublished" :datetime="$moment(data.item.created_at).local().format('YYYY-MM-DD')"></time>
                    <div class="default_item_footer">
                        <ShareButtons :title="data.item.title" :link="'https://okaygorod.com' + this.$route.fullPath" :image="data.item.images && data.item.images.length ? data.item.images[0].image : null" />
                        <span v-html="$moment(data.item.created_at).local().format('DD.MM.YYYY в HH:mm')" class="default_item_footer_date_news"></span>
                    </div>
                </div>
                <VkFrame v-if="data.item && data.item.vk_video && data.item.vk_video.url" :item="data.item.vk_video"></VkFrame>
                <YouTube v-if="data.item && data.item.youtube" :item="data.item.youtube"></YouTube>
                <Comments :url="this.$config.api_url + this.$route.params.city + '/News.get/' + this.$route.params.id" :comments="comments" :users="users"></Comments>
            </div>
            <AdsPlaginNew :direction="'horizontal'" />
        </div>
        <PreLoadBox v-else />
    </div>
</template>
<script>
import Comments from "@/components/Comments/index";
import imageLoader from "@/components/ImageLoader/imageLoader";
import YouTube from "@/components/YouTube/index";
import VkFrame from "@/components/Plagins/VkFrame/index";
import ShareButtons from "@/components/Plagins/ShareButtons";
export default {
    name: "NewsGetNew",
    components: {
        Comments,
        imageLoader,
        YouTube,
        VkFrame,
        ShareButtons,
    },
    metaInfo() {
        return {
            title: this.data && this.data.item && this.data.item.title ? `${this.data.item.title} - Новости - ` : null,
            meta: [
                { name: "description", itemprop: "description", content: this.data && this.data.item && this.data.item.text ? this.data.item.text : `Свежие новости города ${this.$myCity.name} с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город ${this.$myCity.name}` },
                { property: "og:url", content: this.$config.url + this.$route.fullPath },
                { property: "og:title", content: this.data && this.data.item && this.data.item.title ? `${this.data.item.title} - Новости - ${this.$myCity.name}` : `Новости - ${this.$myCity.name}` },
                { property: "og:description", content: this.data && this.data.item && this.data.item.text ? this.data.item.text : `Свежие новости города ${this.$myCity.name} с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город ${this.$myCity.name}` },
                { property: "og:image", content: this.data && this.data.item && this.data.item.images && this.data.item.images[0] && this.data.item.images[0].image ? this.data.item.images[0].image : "https://okaygorod.com/images/logoHD.png" },
                { property: "og:type", content: "article" },
            ],
        };
    },
    data() {
        return {
            preloader: false,
            data: null,
            comments: [],
            users: {},
        };
    },
    methods: {
        api() {
            this.preloader = false;
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + "/Interesting.articleGet/" + this.$route.params.id;
            this.$http
                .get(url)
                .then((response) => {
                    this.data = response.data.response;
                    this.comments = response.data.response.comments;
                    this.users = Object.assign({}, response.data.response.users);
                })
                .catch(() => {
                    this.$router.push({ name: "Home" });
                })
                .finally(() => {
                    this.preloader = true;
                });
        },
    },
    beforeRouteUpdate(to, from, next) {
        this.api();
        next();
    },
    mounted() {
        this.api();
    },
};
</script>
<style scoped>
.content_text {
    text-align: left;
    font-family: "Roboto";
}

.default_box {
    margin-bottom: 20px;
}
</style>
